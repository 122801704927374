@import 'constants';
@import 'mixins';

.wysiwygText {
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
    margin: 2vh 0;
  }

  a {
    text-decoration: underline;
    font-weight: 500;
    &:hover {
      cursor: pointer;
    }
  }

  blockquote {
    margin: 2vh 0;
    font-weight: 300;
    border-left: 1px solid $rove-black;
    padding: 8px 24px;

    @include media('<=phone') {
      padding: 8px 16px;
    }
  }
}
