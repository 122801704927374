@import 'constants';
@import 'mixins';

.radioChoice {
  display: flex;
  align-items: center;
  column-gap: 10px;

  &:hover {
    cursor: pointer;
  }

  .radio {
    background-color: transparent;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.dark {
      border: 1px solid $rove-white;
    }

    &.light,
    &.white {
      border: 1px solid $rove-black;
    }

    &__check {
      display: none;
      width: 8px;
      height: 8px;
      border-radius: 50%;

      &.dark {
        background-color: $rove-white;
      }

      &.light,
      &.white {
        background-color: $rove-black;
      }

      &.isChecked {
        display: flex;
      }
    }
  }

  label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    &:hover {
      cursor: pointer;
    }
  }
}
