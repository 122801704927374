@import 'constants';
@import 'mixins';

.announcementBanner {
  background-color: $rove-black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  font-weight: 300;
  font-size: 16px;
  color: $rove-white;
  text-align: center;
}
