@import 'constants';

.burgerMenu {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: fixed;
  padding: 32px 16px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  background-color: rgba(29, 25, 25, 0.4);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  z-index: $burger-menu-z-index;
  overflow-y: auto;

  &__header {
    margin: 0 2vw 4vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__body {
    display: flex;
    flex-flow: column;
  }

  &__item {
    padding: 1vh 2vw;
    font-weight: 400;
    font-size: 27px;
    line-height: 60px;
    transition: 0.2s linear all;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.withBorderTop {
      border-top: 1px solid rgba(248, 244, 241, 0.5);
    }

    &.highlighted {
      font-size: 36px;

      svg {
        transform: rotate(180deg);
      }
    }

    svg {
      transition: 0.1s linear all;
      font-size: 12px;
    }
  }

  &__links {
    span,
    a {
      padding: 1vh 2vw;
      font-weight: 400;
      font-size: 27px;
      line-height: 60px;
      transition: 0.2s linear all;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid rgba(248, 244, 241, 0.5);
    }
  }

  &__rentals {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;

    & > a {
      width: 100%;
      font-weight: 400;
      font-size: 24px;
      line-height: 50px;
      margin-left: 2vw;

      p {
        margin: 0;
        color: $rove-blue;
      }
    }
  }

  &__footer {
    margin: auto 0 32px 8px;
    display: flex;
    align-items: center;
    column-gap: 24px;

    svg {
      transform: scale(1.2);
    }
  }
}
