@import 'constants';
@import 'mixins';

.creditCard {
  margin: 3vh 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.noMargins {
    margin: 0;
  }

  .labelWrapper {
    display: flex;
    align-items: center;
  }

  .label {
    margin-left: 20px;
    font-weight: 600;
    font-size: 18px;
  }

  .changeBtn {
    font-size: 18px;
    font-weight: 600;
    color: $rove-light-blue;
    cursor: pointer;
    transition: 0.2s linear all;

    &:hover {
      color: $rove-black;
    }
  }
}

.layout {
  &__main {
    max-width: 100vw;
    width: 100%;
  }
}
