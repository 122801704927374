@import 'constants';
@import 'mixins';

$transition: 0.3s linear all;

.header {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 38px;
  width: 100%;
  z-index: $header-z-index;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  transition: $transition;

  @include media('<=laptop') {
    padding: 0 $mobile-page-indent;
  }

  &.light,
  &.white {
    &.isScrolled {
      background: rgba(255, 255, 255, 0.75);

      @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        backdrop-filter: blur(7.5px);
        -webkit-backdrop-filter: blur(7.5px);
      }

      @include media('<=laptop') {
        background: rgba(255, 255, 255, 1);
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
      }

      &.noGlassEffect {
        transition: none;
        background: rgba(255, 255, 255, 1);
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
      }
    }
  }

  &.light {
    color: $rove-black;
  }

  &.white {
    color: rgba($rove-black, 0.6);
  }

  &.dark {
    color: $rove-white;

    &.isScrolled {
      background: rgba(29, 25, 25, 0.75);

      @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        backdrop-filter: blur(7.5px);
        -webkit-backdrop-filter: blur(7.5px);
      }

      @include media('<=laptop') {
        background: rgba(29, 25, 25, 1);
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
      }

      &.noGlassEffect {
        transition: none;
        background: rgba(29, 25, 25, 1);
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
      }
    }
  }

  &__block {
    width: 118px;
    display: flex;
    align-items: center;
    column-gap: 15px;

    a {
      white-space: nowrap;
    }

    &.userBlock {
      direction: rtl;

      @include media('<=laptop') {
        display: none;
      }
    }
  }

  &__capsule {
    border-radius: 50px;
    padding: 0 16px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 38px;
    width: 580px;
    transition: $transition;

    &.light {
      background: $rove-white;
      border: 1px solid $rove-dark-black;
    }

    &.white {
      background: $rove-off-white;
      border: 1px solid rgba(143, 139, 131, 0.5);
    }

    &.dark {
      background: rgba(29, 25, 25, 0.5);
    }

    &.withPhoneNumberOrInbox {
      margin-left: 90px;
      width: 680px;
    }

    &.isScrolled {
      background: transparent;
      border: none;
    }

    @media (max-width: 1368px) {
      margin-left: -80px;

      &.withPhoneNumberOrInbox {
        margin-left: 0;
      }
    }

    @include media('<=laptop') {
      display: none;
    }
  }

  &__dropdown {
    margin-top: 16px;
    background-color: rgba(29, 25, 25, 0.5);
    backdrop-filter: blur(5px);

    &.userDropdown {
      left: unset;
      right: 0;
    }
  }

  &__contactIcons {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }

  &__contactIcon {
    cursor: pointer;
    display: flex;
    text-decoration: none;
  }

  &__phoneNumber {
    font-size: 10px;
    line-height: 24px;
  }

  &__hiUser {
    direction: rtl;
    margin-left: auto;
    text-align: end;
    white-space: nowrap;
    font-weight: 500;
    font-size: 14px;
  }

  &__singUp {
    color: $rove-blue;
  }

  &__burgerIcon {
    display: none;

    @include media('<=laptop') {
      display: flex;
      flex-flow: column;
      row-gap: 5px;
      align-items: center;
      justify-content: center;
      background-color: rgba(143, 139, 131, 0.6);
      width: 24px;
      height: 24px;
      border-radius: 3px;

      & > div {
        background-color: $rove-off-white;
        height: 2px;
        min-height: 2px;
        width: 15.75px;
      }
    }
  }
}
