@import 'constants';

.buttonWithDropdown {
  display: flex;
  align-items: center;
  column-gap: 8px;
  position: relative;
  cursor: pointer;

  svg {
    transition: 0.1s linear all;
  }

  &.isOpen {
    svg {
      transform: rotate(180deg);
    }
  }

  &__dropdown {
    position: absolute;
    background-color: $rove-black;
    color: $rove-white;
    top: 100%;
    left: 0;
    border-radius: 20px;
    z-index: $menu-z-index;
    width: max-content;
    padding: 18px 0;
    display: none;
    flex-direction: column;
    min-width: 150px;
    cursor: unset;

    span,
    a {
      padding: 10px 24px;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      text-align: left;
      min-width: 150px;

      p {
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: $rove-blue !important;
      }

      &:hover {
        background-color: rgba($rove-green, 0.1);
        cursor: pointer;
      }
    }

    &.isOpen {
      display: flex;
    }
  }
}
